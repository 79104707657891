import React from "react";
import AppRouter from '@/router/AppRouter';
import { ToastProvider } from '@stores/ToastStore';

const App = () => {
    return (
        <ToastProvider>
            <AppRouter />
        </ToastProvider>
    );
};

export default App;
