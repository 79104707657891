import React, { createContext, useState } from 'react';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
    const defaultTime = 8000;

    const addToast = (message, type, time = defaultTime, maxW = false) => {

        const id = Date.now()
        setToasts((prev) => [...prev, { id: id, message: message, type: type, time: time, maxW: maxW }]);

        setTimeout(() => {
            removeToast(id)
        }, time);
    };

    const removeToast = (id) => {
        setToasts((prev) => prev.filter((toast) => toast.id !== id));
    };

    return (
        <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
            {children}
        </ToastContext.Provider>
    );
};

export const useToast = () => React.useContext(ToastContext);
