
import LocalStorageHandler from '@helpers/shared/LocalStorageHandler';
import LanguageListResponse from '@responses/LanguageListResponse';
import TranslationListResponse from '@responses/TranslationListResponse';
import CareerDetailResponse from '@responses/CareerDetailResponse';
import CareerListResponse from '@responses/CareerListResponse';
import SlideShowListResponse from '@responses/SlideShowListResponse';
import UrlListResponse from '@responses/UrlListResponse';
import PageModel from '@models/public/PageModel';

class PageHelper {

    sharedTranslationsCached : Boolean;
    lsh = new LocalStorageHandler();
    sharedTranslationsData = [];

    //get URLs
    GetUrlList = async () => {

        this.lsh.CheckCacheValidity();

        let cachedUrls = this.lsh.GetUrls();
        if (cachedUrls.responseCode === 200) {
            if (localStorage.getItem("debug") === "1") {
                console.log(cachedUrls);
                console.log("urls loaded from cache!")
            } 

            return cachedUrls;
        }

        return await PageModel.GetUrlList().then((response) => {
            if (localStorage.getItem("debug") === "1")
                console.log(response);

            if (response.responseCode === 200)
                this.lsh.SetUrls(response.urlList);

            return new UrlListResponse(response.urlList, response.responseCode);
        });
    }

    //get languages
    GetLanguageList = async () => {

        let cachedLanguages = this.lsh.GetLanguages();
        if (cachedLanguages.responseCode === 200) {
            if (localStorage.getItem("debug") === "1")
                console.log("languages loaded from cache!")

            return cachedLanguages;
        }

        return await PageModel.GetLanguageList().then((response) => {

            if (response.responseCode == 200)
                this.lsh.SetLanguages(response.languageList);

            return new LanguageListResponse(response.languageList, response.responseCode);
        });        
    }

    getSlideShow = async () => {

        let cachedSlideShow = this.lsh.GetSlideShow();
        if (cachedSlideShow.responseCode === 200) {
            if (localStorage.getItem("debug") === "1")
                console.log("slideshow loaded from cache!")
            return cachedSlideShow;
        }

        return await PageModel.GetSlideShow().then((response) => {

            if (response.responseCode == 200)
                this.lsh.SetSlideShow(response.slideShowList);

            return new SlideShowListResponse(response.slideShowList, response.responseCode);
        });
    }

    getTranslationList = async (language, page) => {

        this.sharedTranslationsCached = false;
        this.sharedTranslationsData = this.lsh.GetTranslations(language, 'shared');
        this.sharedTranslationsCached = this.sharedTranslationsData.responseCode === 200;

        if (this.sharedTranslationsCached) {

            if (localStorage.getItem("debug") === "1")
                console.log(`translations/${language}/shared loaded from cache!`);

            // notFound uses only shared
            if (page == 'notFound')
                return new TranslationListResponse(this.sharedTranslationsData, 200);

            // pokud jsou nacashovany jak shared, tak i page translations - return rovnou
            let cachedPageTranslations = this.lsh.GetTranslations(language, page);
            if (cachedPageTranslations.responseCode === 200) {
                if (localStorage.getItem("debug") === "1")
                    console.log(`translations/${language}/${page} loaded from cache!`);
                let mergedTranslations = this.sharedTranslationsData.translationList.concat(cachedPageTranslations.translationList);
                return new TranslationListResponse(mergedTranslations, 200);
            }
        }

        if (!this.sharedTranslationsCached) {

            let response = await PageModel.GetTranslationList(language, 'shared');
            if (response.responseCode != 200)
                return response;

            this.sharedTranslationsData = response;
            this.lsh.SetTranslations(language, 'shared', this.sharedTranslationsData.translationList);
        }

        this.lsh.SetLanguage(language);

        // page notFound uses only shared translations
        if (page == 'notFound')
            return this.sharedTranslationsData;

        const translationListResponse = await PageModel.GetTranslationList(language, page);

        if (translationListResponse.responseCode != 200)
            return translationListResponse;

        this.lsh.SetTranslations(language, page, translationListResponse.translationList);
        let mergedTranslations = this.sharedTranslationsData.translationList.concat(translationListResponse.translationList);
        return new TranslationListResponse(mergedTranslations, translationListResponse.responseCode);
    }

    GetLanguage = (allLanguages, defaultLanguage) => {

        let cachedLanguage = this.lsh.GetLanguage(allLanguages);
        if (cachedLanguage != null)
            return cachedLanguage;

        let browserLanguage = navigator.language.substring(0, 2).toLowerCase();
        browserLanguage = allLanguages.find(l => l.code.toLowerCase() === browserLanguage);

        // Na browser v angličtině se nebude brát potaz kvůli google crawleru (aby se náhled webu v google search nezobrazoval v ANG)
        if (browserLanguage && browserLanguage.code != 'en') {
            this.lsh.SetLanguage(browserLanguage.code);
            return browserLanguage.code;
        }

        return defaultLanguage;
    }

    GetCareerList = async (language) => {
        return await PageModel.GetCareerList(language).then((response) => {
            return new CareerListResponse(response.careerList, response.responseCode);
        });
    }

    GetCareerDetail = async (language, name) => {
        return await PageModel.GetCareerDetail(language, name).then((response) => {
            return new CareerDetailResponse(response.career, response.responseCode);
        });
    }
}

export default PageHelper;