export const admRoutes = [
    { path: '', page: 'dashboard' },
    { path: 'translations', page: 'translations', hasActions: true },
    { path: 'users', page: 'users', hasActions: true },
    { path: 'careers', page: 'careers', hasActions: true },
    { path: 'careers/translations', page: 'careers', hasActions: true },
    { path: 'languages', page: 'languages', hasActions: true },
    { path: 'pages', page: 'pages', hasActions: true },
    { path: 'logs', page: 'logs', hasActions: true },
    { path: 'images', page: 'images', hasActions: true },
    { path: 'documents', page: 'documents', hasActions: true },
    { path: 'slideShow', page: 'slideShow', hasActions: true },
    { path: '*', page: 'notFound' },
];
