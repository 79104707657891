import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { admRoutes } from './AdmRoutesConfig';
import useUrlList from '@hooks/UseUrlListHook';

const PublicController = React.lazy(() =>
    import('@components/public/PageController').then((module) => ({
        default: module.PageController,
    }))
);

const AdmController = React.lazy(() =>
    import('@components/adm/PageController')
);

const AppRouter = () => {

    const { urlList, loading } = useUrlList();
    const getLocalizedUrl = (url) => `/${url.language}/${url.url}`;

    const publicRoutes = urlList.map((url) => (
        <Route
            key={url.url}
            path={getLocalizedUrl(url)}
            element={<PublicController page={url.page} language={url.language} urlList={urlList} />}
        />
    ));

    const careerRoutes = urlList
        .filter((url) => url.page === 'career')
        .map((url) => (
            <Route
                key={`${url.url}/career`}
                path={`${getLocalizedUrl(url)}/:name`}
                element={<PublicController page="career" language={url.language} urlList={urlList} />}
            />
        ));

    const admRoutesMapped = () => {

        const GetController = (page) => {
            return <AdmController routeList={admRoutes} page={page} />
        }

        return admRoutes.map((route) => (
            <Route
                key={route.path}
                path={route.path}
                element={GetController(route.page)}
            >
                {!!route.hasActions &&
                    <>
                        <Route
                            path={':action'}
                            element={GetController(route.page)}
                        />                   
                        <Route
                            path={':action/:id'}
                            element={GetController(route.page)}
                        />
                    </>
                }
            </Route>
        ))
    };

    return (
        <BrowserRouter>
            <Suspense fallback={<div className="p-4 opacity-25">Loading...</div>}>
                <Routes>
                    {/* Admin Routes */}
                    <Route path="/_adm">{admRoutesMapped()}</Route>

                    {/* Public Routes */}
                    {!loading ? (
                        <>
                            {/* Home Route */}
                            <Route
                                index
                                element={
                                    <PublicController
                                        page="home"
                                        language={null}
                                        urlList={urlList}
                                    />
                                }
                            />

                            {/* Dynamic Public Routes */}
                            {publicRoutes}

                            {/* Career Routes */}
                            {careerRoutes}

                            {/* Fallback Not Found Route */}
                            <Route
                                path="*"
                                element={
                                    <PublicController
                                        page="notFound"
                                        language={null}
                                        urlList={urlList}
                                    />
                                }
                            />
                        </>
                    ) : (
                        <Route
                            path="*"
                            element={<div className="p-4 opacity-25">Loading...</div>}
                        />
                    )}
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default AppRouter;
